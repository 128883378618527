import {NextPage} from 'next'
import {AppProps} from 'next/app'
import Router from 'next/router'
import {ReactElement, ReactNode, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {ToastContainer} from 'react-toastify'
import {NextUIProvider} from '@nextui-org/react'
import {ConfirmationDialogProvider} from '@/components/shared/AlertDialogs'
import FullscreenLoader from '@/components/UI/progress/FullscreenLoader'


type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

/**
 * Any routes in this list will not show a spinner when they are requested
 */
const noSpinnerRoutes = ['/admin/logout', '/admin/sites']

function isEditor(path: string) {
    return path.indexOf('/admin/edit/') === 0
}
export default function App({Component, pageProps}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  // https://nextjs.org/docs/basic-features/layouts
  const getLayout = Component.getLayout ?? ((page) => page)

  const [isRouteChanged, setIsRouteChanged] = useState(false)
  Router.events.on('routeChangeStart', () => {
    // Honor noSpinnerRoutes
    if (!noSpinnerRoutes.includes(Router.route))
      setIsRouteChanged(true)
  })

  Router.events.on('routeChangeComplete', () => setIsRouteChanged(false))
  Router.events.on('routeChangeError', () => setIsRouteChanged(false))

  return getLayout(
    <NextUIProvider>
      <ConfirmationDialogProvider>
        <FullscreenLoader visible={isRouteChanged} blurred={true}/>
        <Component {...pageProps} />
        <ToastContainer limit={5} position={'bottom-center'} theme={'dark'}/>
      </ConfirmationDialogProvider>
    </NextUIProvider>
  )
}
