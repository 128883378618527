import {Loading, Modal, Row, Text} from '@nextui-org/react'

const FullscreenLoader = (props: {visible: boolean, title?: string, blurred?: boolean}) => {
  return (
    <Modal
      width={props.title === undefined ? '100px' : undefined}
      preventClose
      blur={props?.blurred ?? false}
      aria-labelledby="modal-title"
      open={props.visible}
      style={{
        boxShadow: 'none',
        borderRadius: 30
      }}
    >
      <Modal.Body>
        <Row justify="space-between" style={{
          padding: '20px',
          paddingLeft: props.title === undefined ? '10px' : '20px'
        }}>
          <Loading size={(props.title === undefined ? 'md' : 'sm')}/>
          {props.title ? <Text id="modal-title" size={16}> {props.title} </Text> : <></>}
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default FullscreenLoader
